import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
import DashboardRoutes from './Dashboards';
import PagesRoutes from './Pages';
import Loader from '../components/loader/index';
import { getLocalStorage } from "../redux/auth/index"
import { useSelector } from 'react-redux';
import { selectToken, selectLoading } from "../redux/slice/loginSlice";

// Fallback loading component for lazy loading
const Loading = () => <div className="d-flex justify-content-center align-items-center" style={{ marginTop: '298px' }}><Loader /></div>;
// const navigate = useNavigate();

const FinalRoute = (props) => {
  const isUserLoggedIn = useSelector(selectToken)
  const route = props.route
  if (
    ((!isUserLoggedIn || route.path === undefined) ||
    (!isUserLoggedIn && route.meta && !route.meta.authRoute )) && route.path !== "/login"
  ) {
    /**
     ** If user is not Logged in & route meta is undefined
     ** OR
     ** If user is not Logged in & route.meta.authRoute, !route.meta.publicRoute are undefined
     ** Then redirect user to login
     */

    return <Navigate to="/login" />
  } else if (route.meta && route.meta.authRoute && isUserLoggedIn) {
    // ** If route has meta and authRole and user is Logged in then redirect user to home page (DefaultRoute)
    return <Navigate to="/home" />
  } else {
    // ** If none of the above render component
    return <route.component {...props} />
  }
}

const renderRoutes = (routes) => {
  return routes.map((route, index) => {
    const { path, component: Component, exact, layout: Layout } = route;
    return (
      <Route
        key={index}
        path={path}
        exact={exact}
        element={
          <Suspense fallback={<Loading />}>

            <FinalRoute route={route}>
              {Layout ? (
                <Layout>
                  <Component />
                </Layout>
              ) : (
                <Component />
              )}
            </FinalRoute>
          </Suspense>
        }
      />
    );
  });
};

const AppRouter = () => {
  const allRoutes = [...DashboardRoutes, ...PagesRoutes];
  const isAuthLoading = useSelector(selectLoading)

  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Routes>
          {/* Redirect root to default route */}
          <Route path="/" element={<Navigate to="/login" />} />
          {/* Render all routes */}
          {renderRoutes(allRoutes)}
          {/* Fallback for non-matching routes */}
          <Route path="*" element={<div>404 Not Found</div>} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AppRouter;
