import { lazy } from 'react';

const PagesRoutes = [
  // {
  //   path: "/home",
  //   component: lazy(() => import("../views/pages/chart-dashboard-modules/dashboard-view")),
  //   // component: lazy(() => import("../views/pages/chart-dashboard-modules/dashboard-list")),
  //   exact: true,
  //   meta: {
  //     authRoute: true,
  //     id: "acb89131-49e3-4365-baf2-dc0662bc312d",
  //   },
  // },
  {
    path: "/dashboard/view/:id",
    component: lazy(() => import("../views/pages/chart-dashboard-modules/dashboard-view")),
    exact: true,
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/dashboard/chart/creation/:id",
    component: lazy(() => import("../views/pages/chart-dashboard-modules/chart-creation")),
    exact: true,
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/login",
    component: lazy(() => import("../views/apps/authentication/Login")),
    exact: true,
    layout: "BlankLayout", // Example layout, ensure you have this layout defined
    meta: {
      authRoute: true,
    },
  },
  // Uncomment and add more routes as needed
  // {
  //   path: "/register",
  //   component: lazy(() => import("../../views/apps/authentication/Register")),
  //   exact: true,
  //   layout: "BlankLayout",
  // },
];

export default PagesRoutes;
