// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './lang/en';
console.log('en: ', en);

// Your translations
const resources = {
  en
};

// Initialize i18next
i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // Default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

  console.log('i18n: ', i18n);
export default i18n;